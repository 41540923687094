import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, HammerGestureConfig } from '@angular/platform-browser';
import {NgModule, ErrorHandler, Injectable, APP_INITIALIZER} from '@angular/core';
import { BrowserAnimationsModule ,} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Hammer from 'hammerjs';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { FileSaverModule } from 'ngx-filesaver';
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2Segment} from 'angulartics2/segment';
import {environment} from '@env/environment';
import {AuthInterceptor} from '@core/auth-interceptor';
import {GlobalErrorHandler} from "../GlobalErrorHandler";
import { Router } from "@angular/router";


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {/* Empty Constructor */ }
  handleError(error) {
    if (environment.enableSentry) {
      // This is intentional
    }
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    SharedModule,
    AppRoutingModule,
    FileSaverModule,
    Angulartics2Module.forRoot([Angulartics2Segment])
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    if (window.location.href.indexOf('localhost') === -1) {
      Sentry.init({
        dsn: "https://7990211d638444e6b7e0b2f4afd27c09@o1107930.ingest.sentry.io/6379911",
        integrations: [
          new BrowserTracing({
            tracingOrigins: ["localhost", "https://yourserver.io/api"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        tracesSampleRate: 1.0,
      });
    }
  }
}
