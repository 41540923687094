import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpClient, HttpRequest, HttpHeaders, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '@core/base.service';
import { AuthService } from '@core/auth.service';
import { ToasterService } from '@core/toaster.service';
import { UiwPhaseService } from 'src/app/main/shared/services/uiw-phase.service';

import { environment } from '@env/environment';
@Injectable()
export class AuthInterceptor extends BaseService implements HttpInterceptor {

    constructor(
        public router: Router,
        public toaster: ToasterService,
        public authService: AuthService,
        private uiwPhaseService: UiwPhaseService,
        public http: HttpClient
    ) {
        super(router, toaster);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = this.addAuthenticationToken(req);

        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err && (err.status === 401 || err.status === 404)) {
                    return this.authTokenErrorHndlr(err, err.status);
                }
                // else if (err && (err.status === 0 || err.status > 500)) {
                //     this.router.navigate([`uiworkspace/maintenance`]);
                // }
                else if (err && err.status === 500)
                    this.internalServerErr();
                else {
                    if (!req.url.includes(environment.base_Sketch))
                        return this.genericError(err);
                }
            }),
            map((response: any) => {
                if (req.method.toLocaleLowerCase() === 'put' && !req.url.includes('notoast')) {
                    if (response.body) {
                        this.genericSuccess(req, response);
                    }
                }
                return response;
            })
        );
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        const headers = new HttpHeaders({
            'uiw-now-token': 'irLgelJClLXrAQfqnWgY',
            'uid':this.authService.userProfile.trackerUserData['uid'],
            // 'X-TrackerToken': '6cccd2b300d4c20a94b394aafc6c8e2c0fcc018823ff0bf07e2bd4926030efed',
            'access-token': this.authService.userProfile.trackerUserData['access-token'],
            'client': this.authService.userProfile.trackerUserData['client'],
            'secret-key': (request.url.includes('all_feature_details')) || (request.url.includes('project_details')) ? environment.API_360_KEY_NOTES : environment.API_360_TOKEN,
            'Api-Key': environment.whiteBoardClientSecret
        });
        const cloneReq = request.clone({ headers });
        return cloneReq;
   }

}
